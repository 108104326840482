import styled from "styled-components/macro";
import { makeStyles, TableHead } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { TableRow } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const CategoryBgColors = ["inherit", "#F0F4F5", "#009abb"];
const CategoryFgColors = ["inherit", "inherit", "#009abb"];

export const TemplatesConfigRoot = styled(Paper)`
  && {
    width: 850px;
    min-width: "100em";
    margin: 0 auto 0 auto;
  },
`;

const StudyTableHead = styled(TableHead)`
  && {
    background-color: #666666;
  }
`;

const StudyTableRow = styled(TableRow)`
  && {
    &.totals-row {
      .MuiTableCell-body {
        font-weight: bold;
      }
    }

    background-color: ${(props) =>
      props.highlightrow === "true"
        ? "#EEEEEE"
        : props.althighlight
        ? CategoryBgColors[props.althighlight]
        : "inherit"};

    & .MuiTableCell-body {
      color: ${(props) =>
        props.highlightrow === "true"
          ? "inherit"
          : props.althighlight
          ? CategoryFgColors[props.althighlight]
          : "inherit"};
    }
  }
`;

TemplatesConfigRoot.StudyTableHead = StudyTableHead;
TemplatesConfigRoot.StudyTableRow = StudyTableRow;
