import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableBody,
  TextField,
  Divider,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import NumericFormat from "react-number-format";
import { Theme, useAlerts } from "common";
import { TemplatesConfigRoot, useStyles } from "./TemplatesConfigStyles";
import RichTextEditor from "./RichTextEditor";
import {
  GET_STUDIES_URL_BASE,
  tokensLabelMapping,
  tokenType,
} from "app/shared/constants";
import { combineData } from "app/shared/utils";
import axios from "axios";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";

const headerCell = ["Token", "Value"];

const TemplatesConfiguration = ({ templatesData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [openASConfirmationIndex, setOpenASConfirmationIndex] = useState(null);

  const { values, errors, handleBlur, touched, setFieldValue } =
    useFormikContext();

  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [selectedTempIndex, setSelectedTempIndex] = useState(0);

  const initCombinedData = templatesData.studyTemplates
    ? combineData(templatesData.outreachTemplates, templatesData.studyTemplates)
    : templatesData.outreachTemplates;

  // function to check if there is an error for a specific tokens value in a study template
  const hasError = (templateIndex, tokenIndex) => {
    return (
      Object.keys(errors).length > 0 &&
      Object.keys(touched).length > 0 &&
      errors?.studyTemplates[templateIndex]?.tokens[tokenIndex]?.value &&
      touched?.studyTemplates[templateIndex]?.tokens[tokenIndex]?.value
    );
  };

  const closeCancelASConfirmation = (cancelChoice, template, tempInd) => {
    setOpenASConfirmation(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      (async () => {
        try {
          await axios.delete(
            `${GET_STUDIES_URL_BASE}/${values.id}/${template.templateId}`
          );

          const tempID = initCombinedData[tempInd].templateId;
          const ptemplate = templatesData.outreachTemplates.find(
            (item) => item.templateId === tempID
          );

          setFieldValue(`studyTemplates.[${tempInd}]`, ptemplate);
          setFieldValue(`studyTemplates.[${tempInd}].toRemoveInPatch`, true);
        } catch (err) {
          // set error message in AlertContext
          setAlert("error", err.message);
        }
      })();
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={2}>
        <Paper item className={classes.root}>
          <Typography
            variant="body"
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: Theme.palette.grey[700],
              color: Theme.palette.common.white,
              fontSize: "15px",
              padding: "5px 10px",
            }}
          >
            {t("templateConfigTable.templateCap")}
          </Typography>
          <Divider />
          <List>
            {values.studyTemplates.map((template, tempInd) => (
              <>
                <ListItem
                  button
                  selected={selectedTempIndex === tempInd}
                  onClick={(event) => setSelectedTempIndex(tempInd)}
                >
                  <ListItemText
                    primary={template.templateName}
                    primaryTypographyProps={{ style: { fontSize: "15px" } }}
                  />
                  {template.getStudyTemp === true && (
                    <Button>
                      <DeleteIcon
                        onClick={(e) => {
                          if (
                            template.getStudyTemp &&
                            template.initialGetStudyTemp
                          ) {
                            setOpenASConfirmationIndex(tempInd);
                            setOpenASConfirmation(true);
                          } else {
                            setFieldValue(
                              `studyTemplates.[${tempInd}]`,
                              initCombinedData[tempInd]
                            );
                          }
                        }}
                      />
                    </Button>
                  )}
                </ListItem>

                {openASConfirmationIndex === tempInd && (
                  <ConfirmationDialog
                    open={openASConfirmation}
                    onClose={(e) =>
                      closeCancelASConfirmation(e, template, tempInd)
                    }
                    message={t("templateConfigTable.deleteStudyConfirmMsg")}
                    okLabel={t("cancelConfirmation.okLabel")}
                    cancelLabel={t("cancelConfirmation.cancelLabel")}
                  />
                )}
              </>
            ))}
          </List>
        </Paper>
      </Grid>

      <Grid item xs={10}>
        {/* TEMPLATES STARTS */}
        <TemplatesConfigRoot>
          <TableContainer>
            <Table>
              <TemplatesConfigRoot.StudyTableHead>
                <TemplatesConfigRoot.StudyTableRow>
                  <Grid container>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      {headerCell.map((headCell) => {
                        return (
                          <Grid
                            style={{
                              color: Theme.palette.common.white,
                              padding: "5px 10px",
                              width: "50%",
                              paddingLeft:
                                headCell === "Token" ? "14px" : "132px",
                              fontSize: "15px",
                            }}
                          >
                            {headCell}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </TemplatesConfigRoot.StudyTableRow>
              </TemplatesConfigRoot.StudyTableHead>

              {/* TABLE BODY */}
              <TableBody>
                {/* selectedTempIndex */}
                {values.studyTemplates.length > 0 ? (
                  values.studyTemplates[selectedTempIndex] &&
                  values.studyTemplates[selectedTempIndex]?.tokens?.map(
                    (perTemplate, tokenIndex) => {
                      return (
                        <Grid key={tokenIndex}>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px",
                            }}
                          >
                            <Grid
                              style={{
                                padding: "5px 10px",
                                width: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "15px",
                              }}
                            >
                              {tokensLabelMapping[perTemplate.name]
                                ? tokensLabelMapping[perTemplate.name]
                                : perTemplate.name}
                            </Grid>
                            <Grid
                              style={{
                                width: "300px",
                              }}
                            >
                              {perTemplate.type === tokenType.NUMBER ? (
                                <NumericFormat
                                  fullWidth
                                  required
                                  id={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].name`}
                                  name={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                  variant="outlined"
                                  size="small"
                                  decimalScale={0}
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  value={perTemplate.value}
                                  customInput={TextField}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    style: { fontSize: 15 },
                                  }}
                                  onValueChange={(values) => {
                                    const newValue = values.floatValue ?? "";
                                    setFieldValue(
                                      `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                                      newValue
                                    );

                                    setFieldValue(
                                      `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                                      true
                                    );
                                  }}
                                  error={hasError(
                                    selectedTempIndex,
                                    tokenIndex
                                  )}
                                  helperText={
                                    hasError(selectedTempIndex, tokenIndex) &&
                                    t("formValidation.requiredField")
                                  }
                                />
                              ) : perTemplate.type === tokenType.RICH_TEXT ? (
                                <>
                                  <Grid item>
                                    <Dialog
                                      maxWidth="md"
                                      open={
                                        openDialogIndex?.selectedTempIndex ===
                                          selectedTempIndex &&
                                        openDialogIndex?.tokenIndex ===
                                          tokenIndex
                                      }
                                      onClose={(event, reason) => {
                                        if (reason === "backdropClick") {
                                          // prevent closing on backdrop click
                                          return;
                                        }
                                        setOpenDialogIndex(false);
                                      }}
                                    >
                                      <DialogTitle
                                        style={{
                                          padding: "10px 24px",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {tokensLabelMapping[perTemplate.name]}
                                        </Typography>
                                      </DialogTitle>
                                      <DialogContent
                                        style={{
                                          marginBottom: "10px",
                                          padding: "0px 15px 10px 15px",
                                          width: "850px",
                                        }}
                                      >
                                        <RichTextEditor
                                          setOpenDialog={setOpenDialogIndex}
                                          tokenIndex={tokenIndex}
                                          perTemp={perTemplate}
                                          selectedTempIndex={selectedTempIndex}
                                          setFieldValue={setFieldValue}
                                        />
                                      </DialogContent>
                                    </Dialog>
                                  </Grid>
                                  <Grid
                                    style={{
                                      width: "300px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        setOpenDialogIndex({
                                          selectedTempIndex,
                                          tokenIndex,
                                        })
                                      }
                                    >
                                      {t("templateConfigTable.editToken")}
                                    </Button>
                                  </Grid>
                                </>
                              ) : (
                                <Grid
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  <TextField
                                    id={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                    name={`studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`}
                                    multiline
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onBlur={handleBlur}
                                    inputProps={{
                                      style: { fontSize: 15 },
                                    }}
                                    maxRows={4}
                                    value={perTemplate.value}
                                    onChange={(event) => {
                                      const newValue = event.target.value ?? "";

                                      setFieldValue(
                                        `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                                        newValue
                                      );
                                      setFieldValue(
                                        `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                                        true
                                      );
                                    }}
                                    error={hasError(
                                      selectedTempIndex,
                                      tokenIndex
                                    )}
                                    helperText={
                                      hasError(selectedTempIndex, tokenIndex) &&
                                      t("formValidation.requiredField")
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      );
                    }
                  )
                ) : (
                  <Grid style={{ textAlign: "center" }}>
                    {t("templateConfigTable.noTemplateAvailable")}
                  </Grid>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TemplatesConfigRoot>
      </Grid>
    </Grid>
  );
};

export default TemplatesConfiguration;
