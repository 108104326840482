import { useTranslation } from "react-i18next";
import { Button, Grid, Tooltip } from "@material-ui/core";
import {
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  FormatItalic as FormatItalicIcon,
  StrikethroughS as StrikethroughSIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from "@material-ui/icons";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";

// extensions array
const extensions = [StarterKit, Underline];

const RichTextEditor = ({
  setOpenDialog,
  tokenIndex,
  perTemp,
  selectedTempIndex,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const content = perTemp.value;

  const editor = useEditor({
    extensions,
    content,
  });

  if (!editor) {
    return null;
  }
  const editorValue = editor.getHTML();

  const buttonStyle = {
    margin: "2px 5px",
  };
  return (
    <Grid
      container
      style={{
        border: "1px solid black",
        padding: "10px",
      }}
    >
      <Grid item>
        <Grid
          item
          style={{
            padding: "10px",
          }}
        >
          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.bold")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("bold") ? "contained" : "outlined"}
              onClick={(e) => {
                editor.chain().focus().toggleBold().run();
              }}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              <FormatBoldIcon />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.underline")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("underline") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={editor.isActive("underline") ? "is-active" : ""}
            >
              <FormatUnderlinedIcon />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.italic")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("italic") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
            >
              <FormatItalicIcon />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.strike")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("strike") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "is-active" : ""}
            >
              <StrikethroughSIcon />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.paragraph")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("paragraph") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive("paragraph") ? "is-active" : ""}
            >
              Para
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH1")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 1 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive("heading", { level: 1 }) ? "is-active" : ""
              }
            >
              H1
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH2")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 2 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive("heading", { level: 2 }) ? "is-active" : ""
              }
            >
              H2
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.headingH3")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={
                editor.isActive("heading", { level: 3 })
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive("heading", { level: 3 }) ? "is-active" : ""
              }
            >
              H3
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.bulletedList")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={editor.isActive("bulletList") ? "contained" : "outlined"}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive("bulletList") ? "is-active" : ""}
            >
              <FormatListBulletedIcon />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("templateConfigTable.richTextEditorToolTip.numberedList")}
            arrow
            placement="top"
          >
            <Button
              style={buttonStyle}
              variant={
                editor.isActive("orderedList") ? "contained" : "outlined"
              }
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive("orderedList") ? "is-active" : ""}
            >
              <FormatListNumberedIcon />
            </Button>
          </Tooltip>
        </Grid>
        <EditorContent
          editor={editor}
          style={{
            width: "800px",
            "& .tiptap ProseMirror ProseMirror-focused": {
              outline: "none",
            },
          }}
        />
      </Grid>

      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            {t(`formLabel.buttonCancel`)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // TODO - handle some edge case like more space
              setFieldValue(
                `studyTemplates.[${selectedTempIndex}].tokens.[${tokenIndex}].value`,
                editorValue
              );
              setFieldValue(
                `studyTemplates.[${selectedTempIndex}].getStudyTemp`,
                true
              );

              setOpenDialog(false);
            }}
          >
            {t("formLabel.buttonSave")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RichTextEditor;
